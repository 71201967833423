import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { interval, Observable, startWith, Subject, switchMap, timer } from 'rxjs';

import { SlideImageVm } from 'app/shared/generated/Models/SlideImageVm';

@Component({
    selector: 'pcg-image-slider',
    templateUrl: './image-slider.component.html',
    styleUrls: ['./image-slider.component.scss'],
    standalone: false
})
export class ImageSliderComponent implements OnInit, OnDestroy  {

    @Input() slides: SlideImageVm[] = [];
    @Input() autoSlide: boolean = false;
    @Input() useThumbnails: boolean = true;

    currentIndex: number = 0;
    timeoutId?: number;
    hasImages = true;

    constructor() { }

    ngOnInit() { 
        if (this.slides.length === 0) { this.hasImages = false; }
        this.resetTimer(); 
    }

    resetTimer() {
        if (this.autoSlide === true) {
            if (this.timeoutId) { window.clearTimeout(this.timeoutId); }
            this.timeoutId = window.setTimeout(() => this.goToNext(), 5000);
        }
    }
    
    goToPrevious(): void {
        const isFirstSlide = this.currentIndex === 0;
        const newIndex = isFirstSlide ? this.slides.length - 1 : this.currentIndex - 1;    
        this.resetTimer();
        this.currentIndex = newIndex;
    }
    
    goToNext(): void {
        const isLastSlide = this.currentIndex === this.slides.length - 1;
        const newIndex = isLastSlide ? 0 : this.currentIndex + 1;    
        this.resetTimer();
        this.currentIndex = newIndex;
    }
    
    goToSlide(slideIndex: number): void {
        this.resetTimer();
        this.currentIndex = slideIndex;
    }
    
    getCurrentSlideUrl() { return `url("${this.slides[this.currentIndex]?.url}")`; }
    getCurrentSlideSrc() { return this.slides[this.currentIndex]?.url; }
    getSlidesScr(i: string | number) { return this.slides[i]?.url; }

    ngOnDestroy() { window.clearTimeout(this.timeoutId); }

}
