import { faDollarSign } from '@fortawesome/pro-solid-svg-icons';
import { NavRoute } from '../../shared/navigation/nav.route.interface';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { PermissionRole } from '../enums/generated/PermissionRole';
import { SecurityService } from '../security/security.service';

export const getBANav: () => NavRoute = () => {

	var navItems: NavRoute[] = [
		{
			name: 'Budget Tracking'
			, exact: true
			, path: 'ba/tracking'
			, featureFlag: true
			, activeNavLink: 'ba/tracking'
			, secondaryNav: ['ba', 'Budget Tracking']
		}
		, {
			name: 'Cardinal Invoices'
			, exact: true
			, path: 'ba/cardinal-invoices'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.Inventory ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.Technician)
				}
			]
			, activeNavLink: 'ba/cardinal-invoices'
			, secondaryNav: ['ba', 'Cardinal Invoices']
		}
	];

	return {
		name: 'Budget & Allocation'
		, href: 'ba/tracking'
		//, moduleAccess: [{modules: [BusinessAreaEnum. ], permissionRoles: SecurityService.setMinRole()}]
		, children: navItems
		, icon: faDollarSign
		, area: 'ba'
		, module: BusinessAreaEnum.BA
	};
};
