<div *ngIf="hasImages" class="slider">
    <div *ngIf="slides?.length > 1">
        <div (click)="goToPrevious()" class="left-arrow">❰</div>
        <div (click)="goToNext()" class="right-arrow">❱</div>
    </div>
    <div class="slide">
        <img 
            [src]="getCurrentSlideSrc()" 
            class='img float-right' 
        />
    </div>
    <div *ngIf="useThumbnails && slides?.length > 1" class="thumbnail-container">
        <img 
            *ngFor="let slide of slides; let slideIndex = index" 
            [src]="getSlidesScr(slideIndex)" 
            (click)="goToSlide(slideIndex)" 
            class="pr-2 thumbnail"
        />
    </div>
    <!--Uses dots instead of thumbnails-->
    <div *ngIf="!useThumbnails && slides?.length > 1" class="dots-container">
        <div 
            *ngFor="let slide of slides; let slideIndex = index" 
            class="dot" 
            (click)="goToSlide(slideIndex)"
        >●</div>
    </div>
</div>

    
